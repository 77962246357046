.statementContainer {
    position: relative;
    width:100%;
    max-width:400px;
    padding:1em;
    text-align: left;
    display:inline-block;
    cursor: pointer;
    animation-name: statementContainerAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transform: scale(0);
}

@keyframes statementContainerAnimation {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
}

.statementContainer:hover {
    transform: rotate(2deg);
}

.statementContainer.selected {
    border: 2px solid rgba(255,255,255,1);
}



.statementBackground {
   
    background-image:url(./../../assets/backgrounds/statementBg.svg);
    background-size: 100% 100%;
  
}
.statementUsername {
    font-family: 'Arvo', serif;
    color: rgba(44,255,255, 1);
    font-size: 0.8em;
    margin-Bottom: 0.2em;
}
.statementUserscore {
    font-family: 'Arvo', serif;
    color: rgb(255, 255, 0);
    font-size: 0.8em;
}
.statementCopy {
    font-family: 'Arvo', serif;
    color: rgba(255,255,255, 1);
    font-size: 1.5em;
    margin:0.3em 0em; 
}
.statementComments {
    font-family: 'Arvo', serif;
    color: #ffffff;
    font-size: 0.75em;
  
}

.statementVotingContainer {
    width: 100%;
    padding:1em 0em 0em;
    overflow: auto;
    text-align: center;
}

.statementComment {
    margin-top:5px;
    display: inline-block;
    text-decoration: underline;
    color: rgba(44,255,255, 1);
}

.statementComment:hover {
    color: #ffffff;
}