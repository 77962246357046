.topicTimerContainer {
    display: inline-block;
}

.topicTimerNoDetails {
    background-color: rgba(0,0,0,1);
    padding:0.2em;
}

.topicTimerTitle {
    font-family: 'TapefontAlt';   
    letter-spacing: -0.1em;
    font-size: 1.2em;
    color: rgba(255,255,255,1);
    text-align: center;
}

.topicTimer {
    font-family: 'TapefontAlt';
   
    color: rgba(255,255,255,1);
    font-size: 2.5em;
    letter-spacing: -0.1em;
    text-align: center;
}