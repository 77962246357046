.appOverlayLoading {
    position: fixed;
    top:0px;left:0px;right:0px;bottom:0px;
    z-index: 3000;
}

.appOverlayLoadingContent {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    color: rgba(255,255,0,1);
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 3em;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
}