.appCheckboxContainer {
display: inline-block;
   position: relative;
   text-align:left;
   margin: 0.5em 0.0em;
}

.appCheckboxBox {
    position: absolute;
    z-index: 1;
    top:0px;
    left:0px;
    width:25px;
    height:25px;
    background-image: url(./../../assets/icons/checkboxBox.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.appCheckboxSelected {
    position: absolute;
    z-index: 2;
    width: 25px;
    height: 25px;
    top: -5px;
    left: 5px;
    background-image: url(./../../assets/icons/checkboxSelect.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.appCheckboxCopy {
    padding-top: 0.2em;
    font-size: 0.75em;
    color: #ffffff;
    margin-left: 40px;
    max-width: 250px;
}
