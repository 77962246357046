.loginFormContainer {
    
}

.loginForm {
    margin: 1em auto;
    width: 80%;
    text-align: center;
}

.loginSperator {
    display: inline-block;
    width:20px;
    height: 20px;
  
}

.loginFormFields {
    margin: 1em 0em;
}

.loginDatenschutz {
    margin-top:50px;
    color:#ffffff;
    text-decoration: underline;
    cursor: pointer;
    font-size:0.75em;
    margin-bottom:25px;
}

.loginFormTitle {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 2.5em;
    line-height: 0.7em;
    color: #ffffff;
    margin:1em 0em;
    transform: rotate(-3deg)
}

.loginFormCopy {
    font-size: 1em;
    color: #ffffff;
    margin: 0.5em 0em;
}

.loginFormSeperator {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1.5em;
    color: #ffffff;
    margin:1em 0em;
    transform: rotate(3deg)
}