.appScoreContainer {
    display:inline-block;
}
.appScoreTitle {
    text-align: center;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1.2em;
    color: rgb(231, 53, 18)
}
.appScorePoints {
    text-align: center;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 2.5em;
    color: rgb(231, 53, 18)
}   