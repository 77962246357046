.appTextInputContainer {
    width: 100%;
    text-align: left;
    margin: 0.5em 0.0em;
}

.appTextInputTitle {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    color: rgba(44,255,255,1);
    font-size: 1em;
    padding: 0.0em 0.4em 0.4em 0.4em;
    text-align: center;
}

.appTextInputField {
    width: 100%;
    padding: 0.4em;
    background-image:url(./../../assets/backgrounds/inputBg1.svg);
    background-size: 100% 100%;
}
.appTextInput {
    width: 100%;
    font-size:1.5em;
    background-color:transparent;
    color: #ffffff;
    border: none;
    padding:0.2em 0.5em;
}

