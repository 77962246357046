.appNavigationHeaderContainer {
    position:fixed;
    top:0px;
    left:0px;
    right:0px;
    height: 50px;
    z-index:1000;
    background-color: rgba(0,0,0,0);
    padding:10px 30px;
}

@media only screen and (max-width: 500px) {
    .appNavigationHeaderContainer {
         padding:10px 10px;
         background-color: rgba(0,0,0,0.7);
    }
}

.appNavigationHeaderTitle {
    padding: 7px 0px 0px 5px;
    float: right;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1.5em;
    color: rgba(255,255,255,1);
}

.appNavigationHeaderLogout {
  
    float: right;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1.5em;
  
    color: #ffffff;
    margin-left: 5px;
    padding:3px 3px 0px 0px;
    cursor: pointer;
}

.appNavigationHeaderHelp {
  
    float: right;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1.5em;
    background-color: #000000;
    color: #ffffff;
    margin-left: 5px;
    padding:7px 3px 0px 0px;
    cursor: pointer;
}
.appNavigationHeaderHelp:hover {
  
    background-color: #ffffff;
    color: #000000;
}

.appNavigationHeaderBack {
    float: left;
}