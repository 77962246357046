.appButtonIcon {
   cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: contain;
    vertical-align: middle;
}

.appButtonIcon:hover {
    transform: rotate(2deg);
}

.appButtonIcon.close {
    background-image: url(./../../assets/icons/close.svg);
}

.appButtonIcon.back {
    background-image: url(./../../assets/icons/back.svg);
}

.appButtonIcon.logout {
    background-image: url(./../../assets/icons/logout.svg);
}