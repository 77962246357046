.homeHeadline {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 3.5em;
    color: #ffffff;
    transform: rotate(3deg) scale(0);
    margin: 0.5em 0em 0.3em 0.5em;
    line-height: 0.7em;
    animation-name: homeAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

}

@keyframes homeAnimation {
    0% { transform: rotate(3deg) scale(0);;}
    100% { transform: rotate(3deg) scale(1);}
}

.homeDatenschutz {
    margin-top:50px;
    color:#ffffff;
    text-decoration: underline;
    cursor: pointer;
    font-size:0.75em;
    margin-bottom:25px;
}

.homeCopy {
    position: relative;
    margin:0 auto 1em auto;
    font-size: 1em;
    color: #ffffff;
    max-width: 250px;
    animation-name: homeCopyAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-delay:0.2s;
    transform: scale(0) rotate(-3deg);

}

@keyframes homeCopyAnimation {
    0% { transform: scale(0) rotate(-3deg);}
    100% { transform: scale(1) rotate(-3deg);}
}

.homeUsername {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    position: relative;
    margin:0 auto 1em auto;
    font-size: 1.5em;
    color: rgb(231, 53, 18)
}

.homeHelp {
    display:inline-block;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 2em;
    text-decoration: underline;
    color: rgba(255,255,255,1);
    cursor: pointer;
}

.homeLogoutContainer {
    margin-top:5px;
}

.homeLogout {
    display:inline-block;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1em;
    
    color: rgba(255,255,255,1);
    cursor: pointer;
    padding-left:5px;
}

.homePage {
    position: relative;
    text-align: center;
    color: rgba(255,255,255,1);
    position: relative;
}