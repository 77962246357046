@font-face {font-family: "TapefontAlt"; src: url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.eot"); src: url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.woff") format("woff"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.svg#TapefontAlt") format("svg"); }
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

body {
  margin: 0;
  background-color: #000000;
  font-family: 'Arvo', _serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*height: 100%;*/
  /*overflow: hidden;*/
  background-image: url('./assets/backgrounds/page.jpg');
  background-size: 100vw 100vh;

}

@media only screen and (min-width: 500px) {
  /*
  body {
    background-image: url('./assets/backgrounds/page.jpg');
    background-size: 100vw 100vh;
    background-attachment: fixed;
  }
  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus, input:focus{
  outline: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
