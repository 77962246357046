.topicCounterContainer {
    display: inline-block;
    color: rgba(0,0,0,1);
    text-align: center;
    margin: 0em 1em;
}

.topicCounterContent {
    width:100px;
    text-align: left;
    font-size: 0.8em;
    letter-spacing: -0.1em;
    color:#ffffff;
    transform: rotate(2deg);
}

.topicCounterContentComment {
    width:100px;
    text-align: left;
    font-size: 0.8em;
    letter-spacing: -0.1em;
    color:#ffffff;
    transform: rotate(-3deg);
    margin-top: -0.5em;
}


.topicCounterNumber {
    padding-right: 3px;
    text-align: left;
    font-family: 'TapefontAlt';
    font-size: 3em;
    letter-spacing: -0.1em;
    color:#ffffff;
}
.topicCounterNumber {
    padding-right: 3px;
    text-align: left;
    font-family: 'TapefontAlt';
    font-size: 2em;
    letter-spacing: -0.1em;
    color:#ffffff;
}