.regelnHeadline {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 3.5em;
    color: #ffffff;
    transform: rotate(3deg);
    margin: 1em 0em 0.7em 0.5em;
    line-height: 0.7em;
}

.regelnCopy {
    position: relative;
    margin:0 auto 1em auto;
    font-size: 1em;
    color: #ffffff;
    transform: rotate(-3deg);
    max-width: 250px;
}

.regelnUsername {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    position: relative;
    margin:0 auto 1em auto;
    font-size: 1.5em;
    color: rgb(231, 53, 18)
}

.homeRT {
    margin-top: 40px;
    font-size: 0.7em;
    color: #cccccc;
    padding: 0em 30px;
}

.regelnPage {
    margin-top: 60px;
    position: relative;
    text-align: center;
    color: rgba(255,255,255,1);
    position: relative;
}