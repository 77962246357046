.statementList {
    margin-top: 40px;
    margin-bottom: 100px;
}
.impulseContainer {
    position: relative;
    margin-top: 40px;
    padding:20px;
    animation-name: impulseContainerAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transform: scale(0);
}

@keyframes impulseContainerAnimation {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
}



.impulseContent {
    position: relative;
    font-size: 2em;
    color: #ffffff;
}

.impulseContent::before {
    position: absolute;
    top:-15%;
    left:25%;
    content:'"';
    font-size: 6em;
    transform: rotate(-15deg);
    color:rgba(255,255,255,0.4);
    animation-name: impulseWiggle1;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

@keyframes impulseWiggle1 {
    0% {transform: rotate(-14deg);}
    50% {transform: rotate(-15deg);}
    100% {transform: rotate(-14deg);}
}


.impulseContent::after {
    position: absolute;
    top:30%;
    right:25%;
    content:'"';
    font-size:6em;
    transform: rotate(15deg);
    color:rgba(255,255,255,0.4);
    animation-name: impulseWiggle2;
    animation-duration: 0.15s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

@keyframes impulseWiggle2 {
    0% {transform: rotate(14deg);}
    50% {transform: rotate(15deg);}
    100% {transform: rotate(14deg);}
}