.topicResultsDeciderContainer {
    margin: 0.5em auto;
    padding: 0.5em 0em;
    width: 90%;
    text-align: center;
    border-top: 1px solid rgba(255,255,255,1);
    border-bottom: 1px solid rgba(255,255,255,1);
    color: rgba(255,2555,255,1)
}

.topicResultsDecider {
    font-size: 1.2em;
    font-weight: bold;
}

.topicResultsStatementContainer {
    margin: 1em auto;
    text-align: left;
    width: 90%;
}
.topicResultsStatementUsername {
    font-family: 'Arvo', serif;
    color: rgb(231, 53, 18);
    font-size: 1em;
    margin-Bottom: 0.2em;
}
.topicResultsStatementUserscore {
    font-family: 'Arvo', serif;
    color: rgb(255, 255, 0);
    font-size: 1em;
}


.topicResultsStatement {
    color: rgba(44,255,255,1);
    font-weight: bold;
    font-size:2em;
}



.topicResultsContainer {
    margin: 1em auto;
    text-align: center;
    padding:20px;
    width: 90%;
    background-image: url(./../../assets/backgrounds/inputBg1.svg);
    background-size: 100% 100%;
    
}
.topicResultsCategory {
    margin: 0px;
    padding: 0px;
}