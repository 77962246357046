.topicResultContainer {
    display: inline-block;
    color: rgba(0,0,0,1);
    text-align: center;
    margin: 0em 1em;
}

@media only screen and (max-width: 500px) {
    .topicResultContainer {
     display: block;
    }
}

.topicResultAmount {
    text-align: center;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
}

.topicResultAmount.medium {
    font-size: 1.5em;
}

.topicResultAmount.large {
    font-size: 3em;
}

.topicResultAmount.small {
    font-size: 1em;
}

.topicResultTitle {
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 0.5em 0em;
    border-top: 2px dotted rgba(0,0,0,1);
    font-size: 0.75em;

}

