.votingHeader {
    padding:20px 20px;
    background-color: rgba(30,192,189,1);
    text-align: center;

}

.votingHeadline {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1em;
}

.votingTopic {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 2em;

}

.votingContainer {

}

.votingSeperator {
    display: inline-block;
    width:20px;
}

.votingContainer.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.votingStepContainer {
    margin: 2em 0em;
}
.votingButtonContainer {
    margin-top: 2em;
}

.votingInfoTopContainer {
   padding:2em 0em;
   background-image: url(./../../assets/backgrounds/votingInfoTopBg.svg);
   background-size: 100% 100%;
   letter-spacing: -0.1em;
 
}

.votingInfoTopContainerCopy {
    color: rgba(255,255,255,1);
    max-width:250px;
    font-size:1.25em;
    font-weight:bold;
    margin: 0 auto;
}

.votingInfoTopContainerUser {
    font-size:2em;

}

.votingInfoBottomContainer {
    padding:1em 0em 2em 0em;
    background-image: url(./../../assets/backgrounds/votingInfoBg.svg);
    background-size: 100% 100%;
    letter-spacing: -0.1em;
    line-height: 1.2em;
 }

 .votingInfoBottomContainerCopy {
    color: rgba(0,0,0,1);
    max-width:250px;
    font-size:1.25em;
    font-weight:bold;
    margin: 0 auto;
}

.votingStatementContainer {

    padding:1em 0em;
    font-weight: bold;
    letter-spacing: -0.1em;
    line-height: 1.8em;
    background-image: url(./../../assets/backgrounds/votingStatementBg.svg);
    background-size: 100% 100%;
    letter-spacing: -0.1em;
    color: rgba(255,255,255,1);
  
 }

 .votingCommentContainer {
    display: inline-block;
    padding:1em 0em;
    font-weight: bold;
    letter-spacing: -0.1em;
    line-height: 1.8em;
    background-image: url(./../../assets/backgrounds/votingCommentBg.svg);
    background-size: 100% 100%;
    letter-spacing: -0.1em;
    color: rgba(0,0,0,1);
 }

 .votingResultUsername {
    font-size: 1em;
 }

 .votingResultText {
    font-size: 2em;;
 }

.votingStatementsList {
    position: relative;
    text-align: center;
    margin: 0px auto;
    padding-bottom: 100px;
}

.votingCopy {
    margin: 10px 0px 40px 0px;
}
.votingImpulseContainer {
    position: relative;
   
    padding:20px;
}
.votingImpulseContent {
    position: relative;
    font-size: 2em;
    color: #ffffff;
}

.votingImpulseContent::before {
    position: absolute;
    top:-35%;
    left:25%;
    content:'"';
    font-size: 6em;
    transform: rotate(-15deg);
    color:rgba(255,255,255,0.4);
}
.votingImpulseContent::after {
    position: absolute;
    top:20%;
    right:25%;
    content:'"';
    font-size:6em;
    transform: rotate(15deg);
    color:rgba(255,255,255,0.4);
}