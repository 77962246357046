.voteButtonContainer {
    display: inline-block;
    opacity: 0.5;;
}

.voteButtonContainer:hover {
    opacity: 1;
}

.voteButtonCount {
    display: inline-block;
    vertical-align: middle;
    font-family: 'TapefontAlt';
    color: rgba(44,255,255,1);
    font-size: 2em;
    margin-top:0.2em;
}
.voteButtonCount.likeStatement {
    color: rgba(44,255,255,1);
}
.voteButtonCount.dislikeStatement {
    color: rgba(44,255,255,1);
}
.voteButtonCount.likeComment{
    color: rgba(45,255,205,1);
}
.voteButtonCount.dislikeComment {
    color: rgba(45,255,205,1);
}

.voteButtonIcon {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    margin:0em 0.2em;
}

.voteButtonIcon.likeStatement {
    background-image: url(./../../assets/icons/like.svg);
}
.voteButtonIcon.dislikeStatement {
    background-image: url(./../../assets/icons/dislike.svg);
}
.voteButtonIcon.likeComment{
    background-image: url(./../../assets/icons/likeComment.svg);
}
.voteButtonIcon.dislikeComment {
    background-image: url(./../../assets/icons/dislikeComment.svg);
}

