.appButtonContainer {
    display: inline-block;
    cursor: pointer;

    cursor: pointer;
    padding: 0.5em 2em;
    background-size: 100% 100%;
    opacity: 1;
    margin:0;
    animation-name: appButtonAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transform: scale(0);
}

@keyframes appButtonAnimation {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
}

.appButtonContainer:hover {
    transform: rotate(-2deg);
}

.appButtonContainer.primary {
    background-image:url(./../../assets/backgrounds/buttonBgPrimary.svg);
}

.appButtonContainer.secondary {
    background-image:url(./../../assets/backgrounds/buttonBgSecondary.svg);
}

.appButtonContainer.primary:hover, .appButtonContainer.secondary:hover, .appButtonContainer.alert:hover{
    background-image:url(./../../assets/backgrounds/buttonBgWhite.svg);
}

.appButtonContainer.white {
    background-image:url(./../../assets/backgrounds/buttonBgWhite.svg);
}

.appButtonContainer.transparent {
    color: rgba(0,0,0,1);
    text-decoration: underline;
}

.appButtonContainer.alert {
 background-image:url(./../../assets/backgrounds/buttonBgAlert.svg);
}

.appButtonContainer:hover {
    opacity: 1;
   
}

.appButtonTitle {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    color: rgba(0,0,0,1);
    font-size: 2em;
}