.left {
    float:left;
    
}

.right {
    float:right;
}

.page {
    margin-top: 60px;
    position: relative;
    text-align: center;
    color: rgba(255,255,255,1);
    min-height: 100vh;
    position: relative;
}

.page.voting {
    background-color: rgba(30,192,189,1);
    margin-top: 0px;
}

.toolbar {
    padding-top:10px;
    position: fixed;
    z-index: 1000;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 80px;
    background-color:rgba(0,0,0,0);
    vertical-align: top;
}

@media only screen and (max-width: 500px) {
    .toolbar {
         background-color: rgba(0,0,0,0.7);
    }
}


.toolbarCol {
    vertical-align: middle;
    display:inline-block;
    width:33%;
}




.toolbarCol3 {

}

.toolbarCol3 {

}

@media only screen and (max-width: 500px) {
    .toolbarCol {
        width: 100%;
    }
    .toolbarCol3 {
        display:none;
    }
    .toolbarCol1 {
        display:none;
    }
}

.toolbarCenter {
    text-align: center;
}

.errorMessage {
    color: rgba(255,0,0,1);
    text-align: center;
    font-size: 1em;
}