.fragenSeperator {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1.5em;
    color: #ffffff;
    margin:0.5em 0em;
    transform: rotate(3deg)
}

.fragenHeadline {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 1.5em;
    color: #ffffff;
    transform: rotate(3deg);
    margin: 1.5em 0em 2em 0.5em;
    line-height: 0.7em;
}

.fragenCopy {
    position: relative;
    margin:0 auto 1em auto;
    font-size: 2.2em;
    line-height: 1em;
    letter-spacing: -0.1em;
    color: #ffffff;
    transform: rotate(-3deg);
    max-width: 250px;
    animation-name: statementContainerAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transform: scale(0);
}

@keyframes statementContainerAnimation {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
}

.fragenUsername {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    position: relative;
    margin:0 auto 1em auto;
    font-size: 1.5em;
    color: rgb(231, 53, 18)
}

.fragenHelp {
    display:inline-block;
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 2em;
    text-decoration: underline;
    color: rgba(255,255,255,1);
    cursor: pointer;
}

.fragenPage {
    position: relative;
    text-align: center;
    color: rgba(255,255,255,1);
    position: relative;
}