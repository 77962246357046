.commentContainer {
    position: relative;
    display: inline-block;
    width:100%;
    max-width:400px;
    padding:0em 2em;
    text-align: left;
    animation-name: commentContainerAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transform: scale(0);
}

@keyframes commentContainerAnimation {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
}



.commentContainer.selected {
    border: 2px solid rgba(255,255,255,1);
}

.commentBackground {
    position: absolute;
    z-index: 0;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    background-image:url(./../../assets/backgrounds/statementBg.svg);
    background-size: 100% 100%;
    opacity: 0.2;

}
.commentUsername {
    font-family: 'Arvo', serif;
    color: rgba(45,255,205, 1);
    font-size: 0.8em;
    margin-Bottom: 0.2em;
}
.commentUserscore {
    font-family: 'Arvo', serif;
    color: rgb(255, 255, 0);
    font-size: 0.8em;
}
.commentCopy {
    font-family: 'Arvo', serif;
    color: rgba(255,255,255, 1);
    font-size: 1.3em;
    margin:0.3em 0em; 
}
.commentComments {
    font-family: 'Arvo', serif;
    color: rgba(45,255,205, 1);
    font-size: 0.65em;
    text-decoration: underline;
}

.commentVotingContainer {
    width: 100%;
    padding:1em 0em 0em;
    overflow: auto;
}

.commentLine {
    width:100%;
    height:1px;
    background-color: rgba(255,255,255,0.2);
    margin:2em 0em;
}
