.datenschutzHeadline {
    font-family: 'TapefontAlt';
    letter-spacing: -0.1em;
    font-size: 3.5em;
    color: #ffffff;
    transform: rotate(3deg);
    margin: 1em 0em 0.7em 0.5em;
    line-height: 0.7em;
}

.datenschutzCopy {
    position: relative;
    margin:0 auto 1em auto;
    font-size: 0.75em;
    color: #ffffff;
    max-width: 300px;
}


.datenschutzPage {
    position: relative;
    text-align: center;
    color: rgba(255,255,255,1);
    position: relative;
}