.appOverlayContainer {
    position: fixed;
    z-index: 1002;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.5);
}
.appOverlayContent {
    position: absolute;
    width:90%;
    max-width:300px;
    max-height: 80%;
    top:50%;
    left:50%;
    padding:30px 25px 30px 25px;
    overflow: auto;
    background-color: #ffffff;
    animation-name: appOverlayAnimation;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    transform: scale(0) translate(-50%, -50%);;
}

@keyframes appOverlayAnimation {
    from {transform: scale(0.8) translate(-50%, -50%);;}
    to {transform: scale(1) translate(-50%, -50%);;}
}


.appOverlayTitle {
    color: rgba(0,0,0,1);
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    margin:1em 0em;
}
.appOverlayMessage {
    color: rgba(0,0,0,1);
    font-size: 1em;
    text-align: center;
    margin:1em 0em;
}
