@font-face {font-family: "TapefontAlt"; src: url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.eot"); src: url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.woff") format("woff"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/e4a616ec566614dd7e09383029250aeb.svg#TapefontAlt") format("svg"); }

.topicContainer {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    padding: 0.1em;
    cursor: pointer;
    animation-name: topicContainerAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transform: scale(0);

}



.topicContainer1 {
    animation-delay: .05s;
}
.topicContainer2 {
    animation-delay: .1s;
}
.topicContainer3 {
    animation-delay: .15s;
}
.topicContainer4 {
    animation-delay: .2s;
}
.topicContainer5 {
    animation-delay: .25s;
}
.topicContainer6 {
    animation-delay: .3s;
}


@keyframes topicContainerAnimation {
    from {transform: scale(0.1);}
    to {transform: scale(1);}
  }
  

.topicContainer:hover {
    transform: rotate(2deg);
}

.topicBackground {
    position: absolute;
    top: 0px;
    left: 0px;
    z-Index: 1;
    width: 200px;
    height: 200px;
}

.topicHeadline {
    left: 50%;
    top: 0%;
    transform: rotate(-10deg) translate(-50%, -100%);
    font-family: 'TapefontAlt';
    position: absolute;
    z-Index:10;
    font-size:2.75em;
    letter-spacing: -0.05em;
    line-height: 0.6em;
}

.topicHeadlineUnlocked {
    top:70px !important;
}

.topicLocked {
    position: absolute;
    z-index: 10;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(./images/locked.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 75px;
    height: 75px;
}


.topicIcon {
    position: absolute;
    z-index: 10;
    top: 60%;
    left: 60%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: contain;
    width: 75px;
    height: 75px;
}
.topicIcon0 {
    background-image: url(./../../assets/icons/topics/luck.gif);  
}

.topicIcon3 {
    background-image: url(./../../assets/icons/topics/justice.gif);  
}

.topicIcon2 {
    background-image: url(./../../assets/icons/topics/nature.gif);  
}

.topicIcon1 {
    background-image: url(./../../assets/icons/topics/law.gif);  
}

.topicIcon4 {
    background-image: url(./../../assets/icons/topics/science.gif);  
}

.topicIcon5 {
    background-image: url(./../../assets/icons/topics/society.gif);  
}

.topicIconBlack0 {
    background-image: url(./../../assets/icons/topics/luck_black.gif);  
}

.topicIconBlack3 {
    background-image: url(./../../assets/icons/topics/justice_black.gif);  
}

.topicIconBlack2 {
    background-image: url(./../../assets/icons/topics/nature_black.gif);  
}

.topicIconBlack1 {
    background-image: url(./../../assets/icons/topics/law_black.gif);  
}

.topicIconBlack4 {
    background-image: url(./../../assets/icons/topics/science_black.gif);  
}

.topicIconBlack5 {
    background-image: url(./../../assets/icons/topics/society_black.gif);  
}


.topicTimerPosition { 
    position: absolute;
    bottom:0px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.topicCounterPosition {
    position: absolute;
    z-Index:100;
    top:55%;
    left: 20%;

}

.topicVotesPosition {
    font-family: 'TapefontAlt';
    font-size:1.75em;
    color: #000000;
    letter-spacing: -0.12em;
    position: absolute;
    z-Index:100;
    top:55%;
    left: 20%;

}

.topicLosPosition {
    position: absolute;
    z-Index:100;
    top:37%;
    left: 35%;
    font-weight: bold;
    color: #00ffff;

}




@media only screen and (max-width: 400px) {
    .topicContainer {
        width: 150px;
        height: 150px;
        font-size: 0.75em;
    }
    .topicBackground {
       
        width: 150px;
        height: 150px;
    }
    .topicIcon {
      
        width: 55px;
        height: 55px;
    }
    .topicLocked {
        width: 55px;
        height: 55px;
    }
    
    
}
